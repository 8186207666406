@import "./helpers/normalize.scss";
@import "./helpers/fonts.scss";
@import "./helpers/variables.scss";
@import "./helpers/utils.scss";
@import "./helpers/generic.scss";

/* ------------------------------------------
 *          Componente titles
 * ------------------------------------------*/
.title {
  font-family: $font-plus-jakarta-sans;
  font-weight: $font-medium;
  font-size: 25px;
  line-height: normal;
  letter-spacing: normal;
  color: $gris1;
  margin: 0;

  strong {
    font-weight: $font-regular;
    color: $red;
    display: inline-block;
  }

  &.has-back {
    position: relative;
    padding-top: 25px;
    @include tablet {
      padding-top: 48px !important;
      margin-top: 0;
    }
    @include tablet-large {
      padding-top: 64px !important;
      //margin-top: 0;
    }
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #da291c !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #da291c !important;
}


