@import '../../../../assets/styles/base';

.dialog-wait-message {

    &__content {
        padding: 20px 30px;
        width: 30vw;
        
        @include mobile {
            padding: 15px 20px;
            width: auto;
        }
        
    }
    
    &__title-section{
        display: flex;
        padding: 10px 0;
    }

    &__title-text{
        font-weight: $font-medium;
        font-size: 1.2rem;
    }

    &__body-text {
        padding: 5px 0;
        @include mobile {
            padding: 5px 10px;
         
        }
        
    }
    
}

