@import '../../assets/styles/base';
@import '../../assets/styles/helpers/responsive';
.btn {
    border-radius: 35px;
    border-style:unset;
    font-size: 1.1rem;
    font-weight: $font-bold;
    height: 45px;
    outline: none;
    width: 100%;
    &.enabled {
        background: #DA291C;
        color: #FFFFFF;
        cursor: pointer;
    }
    &.disabled {
        background: $gris4;
        color: $gray-dark;
    }

    &.secondary {
        background: #FFFFFF;
        border: 1px solid $black-base;
        color: $black-base;
    }
}


.btn-notFound {

    @include mobile {
        width: 93%;
       }
}