@import '../../../assets/styles/helpers/variables';
@import '../../../assets/styles/helpers/responsive';


.container {
  background-color: white;
  padding: 10px 25px 15px;
  margin-bottom: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  @include desktop {
    padding-bottom: 20px;
  }

  @include mobile {
    padding: 10px 20px 15px;
  }

  @include mobile-md {
    padding: 10px 15px;
  }

  &__title {
    font-weight: $font-medium;
    font-size: 16px;
    padding: 15px 0 10px;
    margin: 0;

    @include mobile {
      font-size: 14px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    margin-left: 10px;
  }
}