.card-loader {
    background-color: #fff;
    border-radius: 2px;
    height: 150px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:after {
        content: '';
        animation-duration: .8s;
        animation-iteration-count: infinite;
        animation-name: loader-animate;
        animation-timing-function: linear;
        background-color: #636363;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
        border-radius: 10px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &:before {
        content: '';
        background-color: #ededed;
        border-radius: 6px;
        box-shadow: -48px 78px 0 -48px #ededed, -51px 102px 0 -51px #ededed;
        display: block;
        height: 150px;
    }
}

.card {
    border-radius: 16px;
    box-shadow: 8px 8px 24px 2px rgba(54, 54, 54, 0.3);
    height: 138px;
    margin: 50px auto;
    width: 250px;
}

.loader__label_input {
    border-radius: 16px;
    height: 15px;
    width: 130px;
}

.loader___input {
    border-radius: 16px;
    height: 30px;
    margin-top: 10px;
    width: 100%;
}

.card-loader-container__inputs_flex {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.card-loader-container__inputs_medium {
    margin-top: 10px;
    width: 50%;
}

.alert {
    border-radius: 12px;
    box-shadow: 0px 0px 6px rgba(27, 33, 56, 0.15);
    height: 58px;
    width: 447px;
}

.submit-loader {
    align-items: center;
    background-color: #ffffff;
    bottom: 0;
    box-shadow: 0px 0px 6px rgba(27, 33, 56, 0.15);
    display: flex;
    height: 77px;
    left: 0;
    place-content: center;
    position: absolute;
    width: 100%;
}

.submit-loader__button {
    border-radius: 24px;
    width: 399px;
    height: 45px;
}

@keyframes loader-animate {
    0% {
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}