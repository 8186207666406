@import "../../../assets/styles/base";

.invoice-option {
  display: flex;
}

.giftCardContainer {
  display: flex;
  flex-grow: 3;
}

.payment-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.container-pago {
  background: #ffffff;
  border: 1px solid #E5E5E5;
  border-radius: 12px;
  padding: 20px;
  margin: 15px 0;

  &:hover {
    cursor: pointer;
  }
}

.margin-radio {
  margin-right: -16px;
}

.button_giftcard {
  background: #e5e5e5 !important;
  height: 45px;
  border-radius: 24px;
  width: 100%;
}

.button_giftcard-mobile {
  background: #e5e5e5 !important;
  height: 38px;
  margin-top: 8px;
}

.debito {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #282828;
  margin-top: 5px;

  @include mobile {
    display: block;
  }
}

.tooltip {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 19px;
  color: #ffffff;
}

.margin-tooltip {
  margin-top: 6px;
  margin-left: 20px;
}

.logo-transbank-oneClick {
  height: 32px;
  width: 100px;
  margin-top: 4px;
  margin-right: 16px;
  background: #fff url("../../../assets//images/LogoWebpay-oneclick.svg") center center no-repeat;
}

.logo-transbank-webPay {
  height: 32px;
  width: 100px;
  margin-top: 4px;
  margin-right: 14px;
  background: #fff url("../../../assets//images/LogoWebpay-plus.svg") center center no-repeat;
}

.logo-transbank-giftCard {
  height: 32px;
  width: 40px;
  margin-top: 4px;
  margin-right: 14px;
  background: #fff url("../../../assets//images/giftcard.svg") center center no-repeat;
}

.content-input {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin-top: 10px;
}

.box-item {
  display: flex;
  align-items: stretch;
}

.push-item {
  margin-left: auto;
  display: inline-flex;

  @include mobile {
    width: 100%;
  }
}

.push-item-aspa {
  padding-left: 3px;
  font-size: 25px;
  font-weight: $font-medium;
}

.push-item-aspa-mobile {
  padding-left: 2px;
  font-size: 25px;
  font-weight: $font-medium;
}

.switch-wrapper {
  margin-top: 4px;
}

.classTooltip {
  max-width: 16% !important;
}

.webpay-plus {
  color: #282828;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 0;
}

.warn-message {
  color: #da291c;
  font-weight: $font-medium;
  line-height: 2;
}

.padding-text {
  padding-left: 32px;
}

.text-giftcard {
  color: #666666;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-regular;
  letter-spacing: 0em;
  line-height: 19px;
  text-align: left;
}

.textfield {
  margin-left: 36px;
  width: 120ch;

  @include mobile {
    margin-left: 0px;
    width: 100%;
  }
}

.input-giftcard-numero {
  height: 45px;
  width: 95%;
  @include mobile {
    width: 100%;
  }
}

.input-giftcard-numero-mobile {
  width: 100%;
  padding-bottom: 10px !important;
}

.input-giftcard-pin {
  width: 30%;
}

.input-giftcard-pin-mobile {
  margin-top: 10px !important;
  width: 100%;
}

.color-error {
  background: $black-base !important;
  border-radius: 24px;
  color: white !important;
  height: 38px;
  width: 100%;
}

.blocked-disable {
  background: #C4C4C4 !important;
  color: #FFFFFF !important;
}

.margin-giftcard {
  margin-top: -39.8px !important;
  padding-left: 490px;

  @include mobile {
    margin-top: 0px !important;
  }

  @media only screen and (min-width: 1300px) {
    padding-left: 510px;
  }

  @media only screen and (min-width: 1400px) {
    padding-left: 560px;
  }

  @media only screen and (min-width: 1500px) {
    padding-left: 650px;
  }

  @media only screen and (min-width: 1600px) {
    padding-left: 750px;
  }
}

.margin-giftcard-mobile {
  margin-top: -43px !important;
}

.icon-clear-pin {
  position: relative !important;
  margin-top: -6px !important;
  margin-left: -44px !important;
}

.icon-clear-num {
  position: relative !important;
  margin-top: -7px !important;
  margin-left: -72px !important;
  margin-right: 30px !important;

  @include mobile {
    margin-top: -56px !important;
    margin-left: 246px !important;
  }
}

.textoError {
  color: #f80000 !important;
}

.textoOk {
  color: #666666 !important;
}

.alert-giftcard {
  height: 40px;
  background: #ffffff;
  border: 1px solid $gris3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  top: 560px;
  left: 84px;
  right: 1270px;
  width: 30%;

  @include mobile {
    width: 80vw;
    height: 40px;
    top: 130px;
    left: 44px;
    position: static;
  }

  @include monitor-large {
    top: 520px;
    left: 100px;
    right: 1900px;
  }

  & .icon-style {
    margin-left: 20px;

    @include mobile {
      margin-left: 0px;
    }
  }

  & .message {
    margin: 0;
    padding-left: 26px;
    width: 342px;

    @include mobile {
      padding-left: 44px;
      width: 340px;
    }

    @include mobile-md {
      padding-left: 38px;
      width: 250px;
    }
  }
}

@media only screen and (min-width: 340px) and (max-width: 380px) {
  .logo-transbank {
    margin-left: -20px;
  }

  .margin-tooltip {
    margin-left: 35px;
  }
}

// waring icon 

.giftcard-warning {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.warning-icon {
  background-image: url("../../../assets/images/warning-icon.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 20px;
  min-width: 20px;
}

.text-giftcard b{
  color: #666666;
  font-weight: $font-medium;
}