.modal-alert {
    background: #FFFFFF;
    border-radius: 12px;
    bottom: 0;
    height: 242px;
    left: 0;
    margin: auto;
    padding: 24px;
    position: fixed;
    right: 0;
    top: 0;
    width: 328px;

    .dialog-alert {
        height: 242px;
        width: 328px;
    }
}

.modal-alert__content {
    bottom: 0;
    left: 0;
    margin: auto;
    position: relative;
    right: 0;
    top: 0;
    z-index: 99;
    .modal-alert__btn-close {
        border-radius: 50%;
        box-shadow: 0px 0px 8px rgba(27, 33, 56, 0.06);
        height: 34px;
        margin: 0 auto;
        position: absolute;
        right: 24px;
        top: 24px;
        width: 34px;

        .modal-alert__icon-close {
            fill: #666666;
            height: 15.19px;
            width: 15.19px;
        }
    }

    .title {
        color: #282828;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        margin: 32px  auto auto;
        text-align: center;
        width: 90%;
    }

    .description {
        color: #282828;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin: 24px auto;
        text-align: center;
        width: 290px;
    }
    .modal-alert-footer {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .modal-alert__button-action {
        border-radius: 24px;
        bottom: 24px;
        height: 45px;
        margin: auto;
        position: absolute;
        width: 280px;
    }
}

.modal-alert__overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;
}

.modal-alert__icon {
    background: linear-gradient(180deg, #DA291C 0%, #C00000 44.44%);
    border-radius: 40px;
    box-shadow: 0px 0px 8px rgba(27, 33, 56, 0.06);
    height: 60px;
    left: calc(50% - 60px/2);
    margin: 0 auto;
    position: absolute;
    top: -30px;
    width: 60px;
}