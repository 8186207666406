.dialog-alert {
    box-shadow: 0px 4px 8px rgba(27, 33, 56, 0.06);
}

.dialog-alert__content {
    padding: 30px;
}

.MuiPaper-rounded {
    border-radius: 12px !important;
}

.modal {
    display: flex;
    flex-direction: column;
    height: 565px;
    justify-content: start;
    max-height: 565px;
    overflow-y: auto;
    width: 495px;

    .form-check,
    .form-group {
        opacity: 0;
    }

    .modal-title {
        color: #282828;
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        margin: 0;
    }

    .modal-title--container {
        padding: 0 8px;
        width: 100%;
    }

    .modal-header {
        align-items: center;
        border-bottom: 1px solid #E5E5E5;
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        margin: 2px 0 2.5px;

        .modal-header_button {
            align-items: center;
            background-color: #F5F5F5;
            border-radius: 40px;
            border: none;
            display: flex;
            height: 32px;
            outline: none;
            place-content: center;
            width: 32px;
        }

        .modal-header_title {
            align-items: center;
            display: contents;
            gap: 8px;
            width: 100%;
        }

        .modal-header_title hr {
            border-top: 3px solid #bbb;
        }
    }

    .hidden {
        display: none;
    }

    .modal-form_content {
        height: 256px;
    }

    .payment-containers--wapper {
        margin: 0 5px 15px 5px;
        padding: 0 5px 15px 5px;
    }

    .payment-containers--wapper--scrool {
        margin: 0 5px 15px 5px;
        padding: 0 5px 15px 5px;
    }

    .payment-containers {
        height: 400px;
        max-height: 400px;
        min-height: 400px;
        overflow-y: auto;
    }

    .payment-containers--mobile--scrool {
        overflow-y: auto;
    }

    .payment-containers--mobile {
        padding: 0px 12px;
    }

    .form-control-card {
        margin: 0;
        overflow-y: hidden;
    }

    .modal--input__cart {
        border: none;
        font-size: 16px;
        margin: 7px 0 5px 0;
        outline: none;
        width: 100%;
        background: #ffffff;
        color: #3c3c3c;
    }

    .modal--input__cart--active {
        border-bottom: 1px solid #282828;
    }

    .form-label__input {
        color: #282828;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .form-group__info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    }

    .wrapper_input__info {
        display: flex;
        flex-direction: column;
        width: 50%;
    }

    .wrapper_input__info:first-child {
        width: 47%;
    }

    .wrapper_input__info:last-child {
        width: 47%;
    }

    @media only screen and (max-width: 1024px) {
        .form-control-card {
            overflow-y: hidden;
            padding: 15px 38px 0 38px;
        }
    }
}

.modal-image__card {
    height: 16px;
    width: 16px;
}

.modal_wrapper {
    background-color: transparent;
    margin: 5px 24px;
}

.form-group__submit {
    background-color: #ffffff;
    bottom: 0;
    box-shadow: 0px 0px 6px rgba(27, 33, 56, 0.15);
    display: flex;
    left: 0;
    padding: 16px 0px;
    place-content: center;
    position: absolute;
    width: 100%;

    & .enabled {
        background-color: #C00000;
    }
}

.form-group__btn {
    color: #fff !important;
    width: 399px;
}

.form-group__buttons {
    box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0px 0px;
}

.form-co__containers {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
    width: 100%;
}

.form-group__btngrey {
    background-color: #282828 !important;
    color: #fff;
}

.form-group__btnoutlinegrey {
    background-color: #fff !important;
    border: 1px solid #282828;
    border-radius: 24px;
    color: #282828 !important;
}

.wrapper-input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.wrapper-input__image-container {
    background-color: transparent;
    background-size: cover;
    height: 20px;
    margin-right: 10px;
    width: 20px;

    img {
        cursor: pointer;
    }

    .wrapper-input--image__close {
        height: 10px;
        width: 10px;
    }

    .wrapper-input--image__edit {
        height: 20px;
        width: 20px;
    }
}

.wrapper_toast {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: -1px 2px 6px rgb(27 33 56 / 15%);
    display: flex;
    height: 58px;
    margin: 5px 0 26px auto;
    padding: 0 15px 0 15px;
    width: 90%;

    .wrapper_toast-image {
        background-size: cover;
        height: 20px;
        width: 20px;
    }

    p {
        color: #282828;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        margin: 9px 0 11px 11px;
    }
}

.form-container__action {
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
}

.form-container__action_error {
    align-items: center;
    border-bottom: 1px solid #E43C3C;
    display: flex;
    justify-content: space-between;
}

.error-message {
    color: #E43C3C;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}

.error-hidden {
    color: #E43C3C;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    opacity: 0;
}

.cvv-error,
.error-due {
    margin: 0;
}

@media only screen and (max-width: 1024px) {

    .cvv-error,
    .error-due {
        margin: 0;
    }

}

@media only screen and (max-width: 1024px) {
    .modal {
        width: 100%;
        padding-top: 20px;

        .wrapper_toast {
            margin: auto;
            width: 85%;
        }

        .modal-header {
            align-items: start;
            border-bottom: none;
            justify-content: start;
            margin: 0 12px;
            padding: 0;
        }

        .modal-form_content {
            overflow: initial;
            height: 100%;
        }

        .modal-header_button {
            background-color: #F5F5F5;
            border-radius: 40px;
            height: 32px;
            width: 32px;
        }

        .modal-image__card {
            width: 16px;
        }

        .modal-title {
            border-bottom: 1px solid #E5E5E5;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            margin: 0 0 8px 10px;
            padding-bottom: 8px;
            width: 100%;
        }
    }



    .form-group__submit {
        box-shadow: 4px -6px 18px rgba(0, 0, 0, 0.05);
    }

    .disable_btn__klap {
        color: #C4C4C4 !important;
    }

    .btn .enabled {
        color: #C00000 !important;
    }

    .form-group__btn {
        color: #fff;
        margin: 0 20px;
        width: 100%;
    }
}

.modal-backbone {
    display: flex;
    flex-direction: column;
    align-items: center;
}