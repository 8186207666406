.content_modal {
  background-color: #ffffff;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  height: 140px;
  margin: auto;
  position: relative;
  width: 253px;

  .card-front-container-image {
    width: 58px;
  }

  .card-front__img {
    object-fit: cover;
    width: 100%;
  }

  .flip-icon {
    border: 1px solid red;
    bottom: 15px;
    height: 40px;
    position: absolute;
    right: 10px;
    width: 32px;
  }

  .icon-front {
    z-index: 2;
  }

  .icon-back {
    z-index: 4;
  }

  .flip-card {
    background-color: transparent;
    height: 100%;
    perspective: 1000px;
    width: 100%;
  }

  .flip-card-inner {
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    width: 100%;
  }

  .flipped .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back,
  .disable-card {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: linear-gradient(180deg, #DA291C 0%, #C00000 44.44%);
    border-radius: 16px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .flip-card-front__disable-card,
  .flip-card-back__disable-card {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background: linear-gradient(180deg, #A0A0A0 0%, #C7C7C7 44.44%);
    border-radius: 16px;
    filter: grayscale(1);
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
  }

  .flip-card-front {
    overflow: hidden;
    z-index: 1;
  }

  .flip-card-back__disable-card {
    z-index: 1;
  }

  .flip-card-back {
    transform: rotateY(180deg);
    z-index: 3;
  }

  .flip-card-back__disable-card {
    transform: rotateY(180deg);
    z-index: 3;
  }

}

// front

.card-front {
  margin: 18px 28px 22px 28px;

  .card-front__rectangle__is-disabled,
  .card-front__rectangle {
    border-radius: 0px 30.294px 0px 0px;
    height: 134.85px;
    left: -16.12px;
    position: absolute;
    top: 11.52px;
    width: 119.76px;
    z-index: -1;
  }

  .card-front__rectangle__is-disabled {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(46, 46, 46, 0.2) 97.56%);
  }

  .card-front__rectangle {
    background: linear-gradient(180deg, rgba(255, 232, 226, 0.18) 0%, rgba(244, 180, 162, 0.2) 97.56%);
  }

  .card-front__circle {
    background: linear-gradient(180deg, rgba(244, 180, 162, 0.105) 0%, rgba(214, 91, 80, 0.3) 100%);
    border-radius: 50%;
    height: 125.63px;
    position: absolute;
    right: -34.58px;
    top: -34.58px;
    width: 125.51px;
    z-index: -1;
  }

  .text {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  .card-front__content-dots {
    color: #FFFFFF;
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    justify-content: space-between;
    letter-spacing: 3px;
    margin-top: 35px;
    width: max-content;

    .card-front__content-dots__dot {
      background: #FFFFFF;
      border-radius: 50%;
      height: 6px;
      max-height: 6px;
      max-width: 6px;
      min-height: 6px;
      min-width: 6px;
      width: 6px;
    }
  }

  .card-front__name,
  .card-front__due {
    bottom: 10px;
    font-size: 12px;
    margin: 6px 0;
    position: absolute;
    right: 20px;
    text-transform: capitalize;
  }

  .card-front__content__info {
    display: flex;
    justify-content: space-between;
  }
}

// reverse
.card-reverse {
  .text {
    color: #000000;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .card-reverse__content-line {
    background-color: #282828;
    display: flex;
    height: 25px;
    justify-content: space-between;
    margin-top: 16px;
  }

  .card-reverse__name,
  .card-reverse__due {
    font-size: 12px;
    margin: 6px 0;
    text-transform: uppercase;
  }

  .card-reverse__content__info {
    align-items: center;
    background: #FFFFFF;
    display: flex;
    height: 20px;
    justify-content: flex-end;
    margin: 8px auto auto;
    padding-right: 7px;
    width: 184px;
  }
}

.card-front__content-dots__dot {
  background-color: #000000;
  border-radius: 50%;
  height: 8px;
  margin-right: 5px;
  width: 8px;
}

.card-front__content-dots__dot.space {
  margin-right: 15px;
}