@import "../../assets/styles/base";

.title-terms-and-conditions {
  color: #282828 !important;
  font-size: 20px !important;
  justify-content: center;
  margin: auto auto 9px;
}

.dialog-alert {
  overflow: hidden;

  &__content {
    padding: 30px;

    @include mobile {
      padding: 0 20px;
      width: auto;
    }
  }

  &__title-section {
    display: flex;
    text-align: center;

    @include mobile {
      padding: 20px 0px;
    }

    @include tablet {
      padding: 30px 0;
    }

    @include mobile-md {
      padding-left: 0px;
    }

    @include tablet-large {
      padding: 40px 0;
    }
  }

  &__title-text {
    font-size: 1.2rem;
    font-weight: $font-medium;
    margin-bottom: 5px;

    @include mobile {
      width: 60vw;
    }

    @include mobile-md {
      width: 96vw;
    }
  }

  &__btn-wrapper {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
    width: 85%;

    @include mobile {
      margin-top: 20px;
    }

    @include tablet {
      margin-top: 20px;
    }
  }

  &__btn-wrapper .secondary {
    margin-top: 10px;
  }

  &__body-text {
    text-align: center;
    margin-top: 4px;

    @include tablet {
      margin-top: -10px;
    }
    @include mobile {
      margin-top: -10px;
    }
  }
}
.btn_primary {
  background-color: $black-base!important;
  height: 40px;
}

.btn_primary:disabled {
  background: $gris4!important;
}
.icon-error {
  @include tablet {
    height: 65px !important;
    padding-right: 20px !important;
  }
}

.dialog-alert__title-section {
  justify-content: center;
}
