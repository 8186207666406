@import "../../../assets/styles/base";

.dialog-alert__btn-wrapper {
  margin: 20px auto;
}

.busqueda {
  display: flex;
  align-items: initial;

  ul {
    padding: 5px 5px;
    margin-top: -10px;
    margin-bottom: 5px;
    display: inherit;
  }

  span {
    padding-left: 5px;
    padding-right: 3px;
    font-weight: $font-medium;
    vertical-align: 2px;
    font-size: 13px;
    color: #09489b;
    cursor: pointer;
  }

  &__input {
    padding-right: 5px;
    padding-left: 5px;
  }

  &__tag {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 8px;
    list-style: none;
    padding: 15px 8px;
    color: #282828;
    background: #e5e5e5;
    height: 46px;
    width: max-content;

    @include mobile {
      background: white;
      height: auto;
    }

  }
}

.button_cupon {
  border-radius: 0;
  width: 80px;
  height: 50px !important;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.text_cupon {
  height: 22px;
  padding-left: 16.71%;
  padding-right: 55.44%;
  padding-bottom: 193px;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #282828;
}

.active-border {
  border: 1px solid $gray-dark;
}

.temrinos_condiciones {
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 367px;
  line-height: 19px;
  overflow: hidden auto;
  padding-right: 15px;
  text-align: justify;
  width: 100%;

  .dialog-alert {
    height: 530px;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 6px;
  }
}

.temrinos_condiciones_p {
  font-weight: $font-medium;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
}

.terms-btn-wrapper {
  padding: 10px 30px;
  background: #ffffff;

  @include mobile {
    position: sticky;
    position: -webkit-sticky;
    /* Safari */
    bottom: 0;
    z-index: 9999;
  }
}

/* Refactor CSS */

.totalizer-content {
  background-color: #ffffff;
  border-radius: 12px;
  margin-bottom: 15px;
  margin-top: 73px;
  padding: 0 25px 20px;
  position: sticky;
  top: 0;

  @include mobile {
    background-color: #FFFFFF;
    margin-bottom: 0px;
    margin-top: 0 !important;
    padding: 0;
  }
}

.totalizerhr {
  border: 1px solid #E5E5E5;
}

.title-totalizer {
  padding: 10px 0;
  font-size: 16px;
  font-weight: $font-medium;

  & .number_products {
    font-weight: $font-medium;
  }
}

.btn-look-as-a-link {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: black;

  &:link {
    color: black;
  }

  &:visited {
    color: black;
  }

  &:hover {
    color: black;
  }

  &:active {
    color: black;
  }

  & .back-to-cart {
    padding: 0;
  }
}

.totalizer {
  .dialog-alert__title-section {
    justify-content: center; 
  }

  .dialog-alert__btn-wrapper {
    margin: 20px auto;
  }
  .dialog-alert {
    height: auto;
  }
}

.totalizer-row {
  display: flex;
  padding: 7.5px 0;
  justify-content: space-between;
}

.terms-and-conditions {
  color: #666666;
  display: inline;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
  line-height: 21px;
  margin: 0 0 0 13px;

  @include mobile-sm {
    font-size: 11px;
  }

  @include mobile-m {
    font-size: 12px;
  }
}

.link_underline {
  color: #282828;
  font-weight: $font-medium;
  padding: 0;
}

.back-to-cart {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  align-items: center;

  img {
    width: 22px;
    height: 20.53px;
  }

  @include mobile {
    padding: 0px;
  }
}

.btn-pay-wrapper {
  padding: 5px 0;
}

.summary {
  background-color: #FFFFFF;
  @include mobile {
    margin-bottom: 140px;
    padding: 10px 0;
  }
}

.wrapper__terms-and-conditions {
  align-items: center;
  display: flex;
  margin: 25px 0;

  @include mobile {
    margin: 17px 0;
  }
}

.terms-and-pay {
  @include mobile {
    background-color: white;
    bottom: 0px;
    box-shadow: 0px -4px 4px rgba(169, 169, 169, 0.25);
    left: 0;
    padding-top: 10px;
    padding: 20px;
    position: fixed;
    right: 0;
  }
}

.cupon-logo {
  background: url(../../../assets/images/Vector.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  height: 30px;
  width: 30px;
  padding: 10px;
}

.cupon-delete {
  background: url(../../../assets/images/cupon-delete.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  padding: 10px;
  cursor: pointer;
}

.papperCoupon {
  display: flex;
  align-items: center;
  border: 1px solid #666666;
  box-sizing: border-box;
  border-radius: 4px !important;
  overflow: hidden;
  @include mobile {
    width: auto;
  }
}

.container__title-resumen {
  font-weight: 600;
  font-size: 18px;
  padding: 15px 0 10px;
  margin: 0;

  @include mobile {
    font-size: 16px;
  }
}