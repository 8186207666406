@import '../../assets/styles/base';


.dialog-alert-redirect {
    overflow: hidden;
    &__content {
        padding: 20px 40px;
        width: 30vw;
        
        @include mobile {
            width: auto;
        }
        
    }
    
    &__title-section{
        display: flex;
        padding: 10px 0;
    }

    &__title-text{
        font-weight: $font-medium;
        font-size: 1.2rem;
        color: $red-unimarc;
    }

    &__body-text {
        padding: 10px 0;
    }
    &__timeout {
        font-weight: $font-medium;
        color: $red-unimarc;
        margin: 10px 0;
        
    }
}

