.paymentCard__container {
    background: #FFFFFF;
    border-radius: 9px;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding: 12px 20px 15px;
}

.paymentCard__paymentIcon_gift_car {
    margin-bottom: 5px;
    margin-left: 10px;
}

.paymentCard__container--mobile {
    border-radius: 12px;
    cursor: pointer;
    margin-top: 10px;
    padding: 16px 12px;
}


.paymentCard__container--modal {
    margin-top: 8px;
}


.paymentCard__container--modal-mobile {
    margin-top: 8px;
}

.paymentCard__container--border {
    box-shadow: 0px 0px 6px rgba(27, 33, 56, 0.15);
}

.paymentCard__container--clickable {
    cursor: pointer;
}

.paymentCard__container--selected {
    border: 1px solid #FF5A5F;
}

.blocked-payment {
    border: 1px solid #C4C4C4;
}

.blocked-payment * p {
    color: #C4C4C4;
}

.paymentCard__info {
    align-items: center;
    display: flex;
}

.paymentCard__containerIcon {
    align-items: center;
    background: #F5F5F5;
    border-radius: 100%;
    display: flex;
    height: 45px;
    justify-content: center;
    width: 45px;
}

.paymentCard__containerTexts {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.paymentCard__detail {
    margin: 0 0 0 12px;
}

.paymentCard__containerName {
    align-items: center;
    display: inline-flex;
}

.paymentCard__name {
    color: #282828;
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    margin: 0;
}

.paymentCard__name__bold {
    color: #282828;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-weight: 500;
    height: 22px;
    line-height: 22px;
    margin: 0;
}

.paymentCard__name--modal {
    font-size: 14px;
}

.paymentCard__name--mobile {
    font-weight: 500;
    font-size: 12px;
}

.paymentCard__infoIcon {
    background-image: url("../../../../../assets/images/info-circle.svg");
    background-repeat: no-repeat;
    background-size: 13px;
    cursor: pointer;
    height: 13px;
    margin-left: 4px;
    width: 13px;
}

.paymentCard__infoText {
    color: #666666;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
}

.paymentCard__infoText--modal {
    font-size: 12px;
}

.paymentCard__infoText--mobile {
    font-size: 12px;
}

.paymentCard__price {
    color: #282828;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: right;
}

.paymentCard__price--modal {
    color: #282828;
    font-size: 16px;
}

.paymentCard__price--discount{
    color: #DA291C;
    font-weight: 600;
    font-size: 16px;
}

.paymentCard__price--mobile {
    font-size: 13px !important;
}

.paymentCard__add {
    color: #FF5A5F;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
}

.paymentCard__cancel {
    color: #FF5A5F;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
}

.paymentCard__price--mobile {
    font-size: 14px;
}

.paymentCard__card {
    align-items: center;
    display: flex;
    margin-left: 48px;
}

.paymentCard__cardIcon {
    margin-right: 17px;
}

.newPayment__cardIcon {
    background-image: url("../../../../../assets/images/card.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    height: 20px;
    width: 20px;
}

.paymentCard__cardNumber {
    color: #666666;
    flex-grow: 0;
    flex: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    order: 3;
}

.paymentCard__editAction {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    margin-top: 1px;
}

.paymentCard__editIcon {
    background-image: url("../../../../../assets/images/edit.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    height: 20px;
    margin-right: 6px;
    width: 20px;
}

.paymentCard__editText {
    color: #282828;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    text-decoration-line: underline;
    text-decoration: underline;
}

.paymentCard__paymentIcon {
    align-items: center;
    position: relative;
    border-radius: 100%;
    display: flex;
    height: 45px;
    justify-content: center;
    min-width: 45px;
    width: 45px;
}

.paymentCard__arrowRightIcon {
    background-image: url("../../../../../assets/images/arrowRight.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    cursor: pointer;
    height: 20px;
    margin-left: 12px;
    width: 20px;
}

.paymentCard__arrowRightIcon {
    background-size: 14.63px;
    height: 14.63px;
    width: 14.63px;
}

/* new payment */

.newPayment__container {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.newPayment__addText {
    color: #282828;
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 0 16px;
}

.newPayment__cardContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: -12px;
    padding: 0 14px;
}

.newPayment__shieldContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.newPayment__shieldIcon {
    background-image: url("../../../../../assets/images/shieldTick.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    cursor: pointer;
    height: 20px;
    margin-right: 4px;
    width: 20px;
}

.newPayment__shieldText {
    color: #000;
    font-size: 12px;
    line-height: 16px;
}

.logo__visa {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 33px;
    background: url(../../../../../assets/images/visa.svg);
    height: 24px;
    width: 33px;
}

.logo__RedCompra {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 33px;
    background: url(../../../../../assets/images/redcompra.svg);
    height: 33px;
    width: 33px;
}

/* PAYMENT METHODS ICONS */

.paymentCard__giftcard {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 32px;
    background-image: url(../../../../../assets/images/paymentMethods/giftCard.svg);
    height: 32px;
    width: 32px;
}

.paymentCard__giftcard__disable {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 32px;
    background-image: url(../../../../../assets/images/paymentMethods/disableGiftCard.svg);
    height: 32px;
    width: 32px;
}

.paymentCard__klap,
.paymentCard__oneclick {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    background: url(../../../../../assets/images/paymentMethods/debit.svg);
    height: 24.5px;
    width: 25px;
}

.paymentCard__credit {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    background: url(../../../../../assets/images/paymentMethods/credit.svg);
    height: 18px;
    width: 25px;
}

.paymentCard__unipay {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    background: url(../../../../../assets/images/paymentMethods/unipay.svg);
    height: 18px;
    width: 25px;
}

.paymentCard__visa {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 33px;
    background: url(../../../../../assets/images/visa.svg);
    height: 10px;
    width: 33px;
}

.paymentCard__mastercard {
    background-position: center;
    background-repeat: no-repeat !important;
    background-size: 33px !important;
    background: url(../../../../../assets/images/mastercard.svg);
    height: 22px;
    width: 33px;
}

.paymentCard__prepago {
    background: url(../../../../../assets/images/paymentMethods/debit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    height: 18px;
    width: 25px;
}

.paymentCard__redcompra {
    background: url(../../../../../assets/images/redcompra.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
    height: 50px;
    width: 40px;
}

.paymentCard__americanexpress {
    background: url(../../../../../assets/images/americanExpress.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    height: 18px;
    width: 25px;
}

.paymentCard__credits {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    background: url(../../../../../assets/images/paymentMethods/credits.svg);
    height: 20px;
    width: 20px;
}

.paymentCard__newcard {
    background: url(../../../../../assets/images/paymentMethods/card.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
    height: 18px;
    width: 25px;
}

.paymentCard__webpay {
    background: url(../../../../../assets/images/paymentMethods/credit2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    height: 30px;
    width: 29px;
}

.paymentCard__splitPayment {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
}

.paymentCard__bancoestado {
    background: url(../../../../../assets/images/paymentMethods/bancoestado.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 28px;
    height: 28px;
    width: 28px;
}

.content_promotions {
    width: 32px;
    height: 15px;
    padding: 2px 4px;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 4px;
    background-color: #F39800;
}

.paymentCard__oneclickDebit {
    background: url(../../../../../assets/images/paymentMethods/oneclickDebit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    height: 30px;
    width: 29px;
}

.paymentCard__oneclickCredit {
    background: url(../../../../../assets/images/paymentMethods/oneclickCredit.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    height: 30px;
    width: 29px;
}

.paymentCard__bancoEstadoCard {
    background: url(../../../../../assets/images/paymentMethods/bancoEstadoCard.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    height: 30px;
    width: 29px;
}