@import '../../../../assets/styles/base';

.loader-card{
    position: absolute;
    top: 40px;
    left: 85px;
    color: #DA291C !important;
    margin-top: 3px;

    &.is-loading {
        color: $gray-text !important;
        position: relative;
        display: block;
        left: 45%;
        top: -10px;
    }
}
.oneclick-card {
    max-width: 219px;
    max-height: 121px;
    background: $gray-bg;
    border: 1.28723px solid $gris3;
    box-sizing: border-box;
    box-shadow: 0px 0px 6.43617px rgba(0, 0, 0, 0.15);
    border-radius: 15.4468px;
    margin-right: 22px;
    position: relative;

    &__card-number {
        color: $gray-dark;
        font-size: 15px;
        padding: 5px 0;
    }
    &__card-Title {
        color: $gray-dark;
        font-size: 12px;
        padding: 5px 0;
        justify-content: flex-start;
        position: absolute;
        left: 15px;
    }
        &__card-TitleSaldo {
            color: $gray-dark;
            font-size: 12px;
            padding: 5px 0;
           }
    &__content {
        margin-top: -20px;
        padding: 0 15px;
    }

    @include mobile {
        flex: 0 0 auto;
        max-width: 200px;
    }
}

.active {
    background: #FFE9E9;
    border: 1px solid $red-unimarc;
}


.trash-icon__circle {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: $gris4;
    cursor: pointer;
}

.trash-icon__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 5px;
}

.trash-icon__circle.icon-active {
    background: #FED7D7;
}

.trash-icon-style {
    width: 15.45px !important;
    height: 15.45px !important;
    background-size:15.45px !important;
    margin: auto !important;
    padding-top: 18.45px !important;
}


.textfield {
    margin-left: 36px;
    width: 120ch;
  
    @include mobile {
      margin-left: 0px;
      width: 100%;
    }
  }
  
