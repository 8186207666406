.modal {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 495px;
}

.payment-alert-klap {
  max-width: 340px;
  margin: 16px 10px;
  display: flex;
  flex-direction: column;
  height: 44px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #fff4f4;
  margin: 16px auto;
}

.payment-alert-klap > p {
  margin: 0;
  color: #282828;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* desktop to payment-alert-klap */
@media (min-width: 768px) {
  .payment-alert-klap {
    height: 48px;
    max-width: 413px;
    padding: 4px 23px;
    gap: 0px;
    margin: 20px auto;
  }

  .payment-alert-klap > p {
    font-size: 16px;
  }
}
