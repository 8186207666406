.wrapper-toggle {
    display: flex;
  }
  
  .check-box-wrapper {
    margin-top: 10px;
    position: relative;
    width: 38px;
  }
  
  .check-box-label {
    background: #E5E5E5;
    border-radius: 32px;
    cursor: pointer;
    height: 23px;
    left: 0;
    padding: 1px;
    position: absolute;
    top: 0;
    width: 38px;
  }
  
  .check-box-label::after {
    background: #ffffff;
    border-radius: 50%;
    box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    content: "";
    display: block;
    height: 20px;
    margin: 1px;
    transition: 0.2s linear;
    width: 20px;
  }
  
  .check-box {
    border-radius: 15px;
    height: 26px;
    margin: 0 0 10px 0;
    opacity: 0;
    width: 42px;
  }
  
  .check-box:checked + .check-box-label {
    background: #282828;
  }
  
  .check-box:checked + .check-box-label::after {
    border-radius: 32px;
    content: "";
    display: block;
    height: 20px;
    margin-left: 16px;
    transition: 0.2s linear;
    width: 20px;
  }
  
