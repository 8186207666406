//fonts
$font-lato: 'lato', Helvetica, Arial;
$font-roboto: 'roboto', Helvetica, Arial;
$font-sans: 'Open Sans', sans-serif;
$font-plus-jakarta-sans : 'Plus Jakarta Sans';

// font weights
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 600;
$font-bolder: 700;

//colors
$gris1: #282828;
$gris3: #c4c4c4;
$gris4: #e5e5e5;
$gray-dark: #666666;

$gray1: #494F66;
$gray2: #676F8F;
$gray3: #A9B1D1;
$gray4: #EDEFF5;
$gray5: #FAFAFC;
$gray6: #D4D5E8;
$gray7: #C1C6DB;
$gray8: #6E7799;
$lila1: #6F7DFF;
$lila2: #939DFF;

$red1: #E41B13;
$red: #FC4E55;
$red2: #FC4E55;
$red3: #EF3340;
$red4: #FC4E55;
$red-unimarc: #DA291C;

$pink: #FC4E55;
$pink2: #FC4E55;
$pink3: #FFD7DA;
$pink4: #FEE7E9;
$blue: #09489B;
$gris-fondo: #F9F9F9;
$gray-text: #282828;
$gray-dark: #666666;
$gray-bg: #F5F5F5;
$black-base: #282828;

$green: #92D66F;
$green2: #CFEDC0;
$green3: #E9F7E2;
$green4: #83CC5E;
$orange: #FFAF3A;
$orange2: #FFEFD8;
$orange3: #FFA733;
